// src/constants/glossaryTerms.js

export const glossaryTerms = {
    'en': {
      'Pitch deck': 'A presentation used to showcase a business idea to potential investors.',
      'Ordinary resolutions': 'Decisions made by the shareholders of a company that require a simple or smaller majority vote, because they are routine.',
      'Extraordinary resolutions': 'Decisions made by the shareholders of a company that require a higher majority vote than ordinary resolutions, because they are more significant.',
      'Call-option': 'An agreement that gives the buyer (e.g. other existing shareholders) the preferential right to buy an asset (generally shares of the company) at a specified price within a specific time period.',
      'Handelsregister': 'The German commercial register is the public directory that contains information about companies and their legal representatives.',
      'VESOP': 'A virtual employee stock option plan (VESOP) is a plan that allows employees to earn virtual shares of the company.',
      'backing of shares': 'Every virtual share must be backed by a real share in the company to ensure that the virtual share has value.',
      'PII': 'Personally Identifiable Information (PII) is any information that can be used to identify an individual.',
      'GPDR': 'The General Data Protection Regulation (GDPR) is a regulation in EU law on data protection and privacy in the European Union and the European Economic Area.',
      'DSGVO': 'The General Data Protection Regulation (GDPR, in german: DSGVO) is a regulation in EU law on data protection and privacy in the European Union and the European Economic Area.',
      'PIPEDA': 'The Personal Information Protection and Electronic Documents Act (PIPEDA) is a Canadian law relating to data privacy.',
      'FISMA': 'The Federal Information Security Management Act (FISMA) is a United States federal law that defines a comprehensive framework to protect government information, operations and assets against data breaches.',
      'implicit consent is not sufficient': 'Consent to process this personal data must be voluntary and explicit, meaning it must be given expressly in relation to that data.',
    },
    'de': {
      'Pitch Deck': 'Eine Präsentation, die verwendet wird, um eine Geschäftsidee potenziellen Investoren vorzustellen.',
      'Ordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine einfache oder kleinere Mehrheitsentscheidung erfordern, weil sie routinemäßig sind.',
      'Außerordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine höhere Mehrheitsentscheidung als ordentliche Beschlüsse erfordern, weil sie bedeutender sind.',
      'Call-Option': 'Eine Vereinbarung, die dem Käufer (z. B. anderen bestehenden Aktionären) das Vorzugsrecht zum Kauf eines Vermögenswerts (in der Regel Aktien des Unternehmens) zu einem festgelegten Preis innerhalb eines bestimmten Zeitraums einräumt.',
      'Handelsregister': 'Das deutsche Handelsregister ist das öffentliche Verzeichnis, das Informationen über Unternehmen und ihre gesetzlichen Vertreter enthält.',
      'VESOP': 'Ein virtueller Mitarbeiteraktienoptionsplan (VESOP) ist ein Plan, der es Mitarbeitern ermöglicht, virtuelle Aktien des Unternehmens zu verdienen.',
      'Hinterlegung der Anteile': 'Jeder virtueller Anteil muss durch einen realen Anteil im Unternehmen abgesichert sein, um sicherzustellen, dass der virtuelle Anteil einen Wert hat.',
      'PII': 'Persönlich Identifizierbare Informationen (PII) bzw. personenbezogene Daten sind Informationen, die verwendet werden können, um eine Person zu identifizieren.',
      'DSGVO': 'Die Datenschutz-Grundverordnung (DSGVO) ist eine Verordnung im EU-Recht zum Datenschutz und zur Privatsphäre in der Europäischen Union und im Europäischen Wirtschaftsraum.',
      'GDPR': 'Die Datenschutz-Grundverordnung (DSGVO, auf Englisch: GDPR) ist eine Verordnung im EU-Recht zum Datenschutz und zur Privatsphäre in der Europäischen Union und im Europäischen Wirtschaftsraum.',
      'PIPEDA': 'Das Gesetz zum Schutz personenbezogener Informationen und elektronischer Dokumente (PIPEDA) ist ein kanadisches Gesetz zum Datenschutz.',
      'FISMA': 'Das Bundesgesetz zum Informationsicherheitsmanagement (FISMA) ist ein US-Bundesgesetz, das einen umfassenden Rahmen zur Sicherung von Regierungsinformationen, -operationen und -vermögenswerten gegen Datenverstöße definiert.',
      'konkludente Zustimmung reicht nicht aus': 'Die Zustimmung zur Verarbeitung dieser personenbezogenen Daten muss freiwillig und explizit, also ausdrücklich im Bezug auf diese Daten, sein.',
    },
  };