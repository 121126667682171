// src/constants/countryData.js
import { useState, useEffect } from 'react';
import axios from '../axios'; // Import your configured axios instance

// This array will be populated once data is loaded
export let countryData = [];

// Cache for country data to avoid repeated API calls
let fetchPromise = null;
let lastFetchTime = null;
const CACHE_DURATION = 3600000; // 1 hour in milliseconds

// Function to fetch country data from API with improved error handling
const fetchCountryData = async () => {
  const now = Date.now();
  
  // Return existing promise if one is in progress
  if (fetchPromise && lastFetchTime && now - lastFetchTime < CACHE_DURATION) {
    return fetchPromise;
  }
  
  // Create a new promise for fetching data
  fetchPromise = new Promise(async (resolve, reject) => {
    try {
      
      // Use axios instead of fetch
      const response = await axios.get('/api/v1/static_data/countries');
      
      // Update the exported countryData array to maintain compatibility
      countryData = response.data;
      lastFetchTime = now;
      
      resolve(response.data);
    } catch (error) {
      console.error('Error fetching country data:', error);
      
      // If we have data already, resolve with that
      if (countryData.length > 0) {
        console.log('Using previously cached data due to API error');
        resolve(countryData);
      } else {
        // Otherwise reject the promise
        reject(error);
      }
    }
  });
  
  return fetchPromise;
};

// Initialize data immediately
fetchCountryData().catch(err => console.error('Initial country data load failed:', err));

// Helper functions with backward compatibility - they work synchronously if data is available,
// otherwise they will fetch data asynchronously

export const getCountryByCode = (code) => {
  // If data is already loaded, return immediately
  const result = countryData.find(country => country.code === code);
  if (result || countryData.length > 0) return result;
  
  // Otherwise, fetch data and then find the country
  return fetchCountryData().then(data => data.find(country => country.code === code));
};

export const getCountryByName = (name) => {
  const result = countryData.find(country => country.name === name);
  if (result || countryData.length > 0) return result;
  return fetchCountryData().then(data => data.find(country => country.name === name));
};

export const getCountriesBySubregion = (subregion) => {
  const result = countryData.filter(country => country.subregion === subregion);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.subregion === subregion));
};

export const getCountriesByRegion = (region) => {
  const result = countryData.filter(country => country.region === region);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.region === region));
};

export const getCountriesByEU = () => {
  const result = countryData.filter(country => country.EU);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.EU));
};

export const getCountriesByGDPR = () => {
  const result = countryData.filter(country => country.GDPR);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.GDPR));
};

export const getCountriesByNIS2 = () => {
  const result = countryData.filter(country => country.NIS2);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.NIS2));
};

export const getCountriesByFISMA = () => {
  const result = countryData.filter(country => country.FISMA);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.FISMA));
};

export const getCountriesByPIPEDA = () => {
  const result = countryData.filter(country => country.PIPEDA);
  if (countryData.length > 0) return result;
  return fetchCountryData().then(data => data.filter(country => country.PIPEDA));
};

// React hook for components that need to ensure data is loaded
export const useCountryData = () => {
  const [data, setData] = useState(countryData.length > 0 ? countryData : []);
  const [loading, setLoading] = useState(countryData.length === 0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (countryData.length === 0) {
      fetchCountryData()
        .then(result => {
          setData(result);
          setLoading(false);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  return { data, loading, error };
};

// For convenience, also export a function to get all country data
export const getCountryData = async () => {
  if (countryData.length > 0) return countryData;
  return fetchCountryData();
};