import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon, PlusIcon, CheckIcon } from '@heroicons/react/24/solid';
import WizardDrawer from './WizardDrawer';
import AssetSelectionModal from '../asset_management_components/AssetSelectionModal';
import AssetCard from '../asset_management_components/AssetCard';
import PersonSelector from '../asset_management_components/PersonSelector';

// Translation utility for retention periods
const translateRetentionPeriod = (t, period) => {
  const [value, unit] = period.split(' ');
  const translatedUnit = unit === 'Years'
    ? t('DataPrivacyWizard.years')
    : unit === 'Days'
      ? t('DataPrivacyWizard.days')
      : unit;
  return `${value} ${translatedUnit}`;
};

const ProcessingActivityForm = ({
  existingData,
  onSave,
  onCancel,
  dataTypes = [], // Provide a default empty array
  riskControls = [] // Same for risk controls
}) => {
  const { t, i18n } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
  const [associatedAssetModalOpen, setAssociatedAssetModalOpen] = useState(false);
  const [internalRecipientsModalOpen, setInternalRecipientsModalOpen] = useState(false);
  const [externalRecipientsModalOpen, setExternalRecipientsModalOpen] = useState(false);

  // Method to get the user's language
  const getUserLanguage = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData?.language ? userData.language.slice(0, 2) : 'en';
  };

  const ASSET_FILTER_CONSTANTS = {
    // Asset class IDs for internal recipients (Organizational Structure)
    ORGANIZATIONAL_STRUCTURE_CLASS_ID: 'AL51',

    // Asset class IDs for external recipients (Suppliers/Vendors and Partnerships)
    SUPPLIERS_VENDORS_CLASS_ID: 'AL46',
    PARTNERSHIPS_CLASS_ID: 'AL42',

    // Human category ID (for PersonSelector)
    HUMAN_CATEGORY_ID: 'AC04'
  };

  // Separate special and non-special categories
  const [dataCategories, setDataCategories] = useState(() => {
    const categories = [
      { id: 'name', label: t('DataPrivacyWizard.dataCategories.name'), isSpecial: false },
      { id: 'alias_nickname', label: t('DataPrivacyWizard.dataCategories.alias'), isSpecial: false },
      { id: 'email', label: t('DataPrivacyWizard.dataCategories.email'), isSpecial: false },
      { id: 'date_of_birth', label: t('DataPrivacyWizard.dataCategories.dob'), isSpecial: false },
      { id: 'nationality_place_of_birth', label: t('DataPrivacyWizard.dataCategories.nationality'), isSpecial: false },
      { id: 'gender', label: t('DataPrivacyWizard.dataCategories.gender'), isSpecial: false },
      { id: 'phone', label: t('DataPrivacyWizard.dataCategories.phone'), isSpecial: false },
      { id: 'home_address', label: t('DataPrivacyWizard.dataCategories.address'), isSpecial: false },
      { id: 'work_info', label: t('DataPrivacyWizard.dataCategories.workInfo'), isSpecial: false },
      { id: 'social_media_info', label: t('DataPrivacyWizard.dataCategories.socialMedia'), isSpecial: false },
      { id: 'government_issued_info', label: t('DataPrivacyWizard.dataCategories.governmentId'), isSpecial: false },
      { id: 'financial_info', label: t('DataPrivacyWizard.dataCategories.financial'), isSpecial: false },
      { id: 'employment_education_data', label: t('DataPrivacyWizard.dataCategories.employment'), isSpecial: false },
      { id: 'ip_address', label: t('DataPrivacyWizard.dataCategories.ipAddress'), isSpecial: false },
      { id: 'device_info', label: t('DataPrivacyWizard.dataCategories.deviceInfo'), isSpecial: false },
      { id: 'geolocation', label: t('DataPrivacyWizard.dataCategories.geolocation'), isSpecial: false },
      { id: 'login_credentials', label: t('DataPrivacyWizard.dataCategories.loginCredentials'), isSpecial: false },
      { id: 'metadata', label: t('DataPrivacyWizard.dataCategories.metadata'), isSpecial: false },
      { id: 'browsing_history', label: t('DataPrivacyWizard.dataCategories.browsingHistory'), isSpecial: false },
      { id: 'preferences_opinions', label: t('DataPrivacyWizard.dataCategories.preferencesOpinions'), isSpecial: false },
      { id: 'online_behavior', label: t('DataPrivacyWizard.dataCategories.onlineBehavior'), isSpecial: false },
      { id: 'purchasing_history', label: t('DataPrivacyWizard.dataCategories.purchasingHistory'), isSpecial: false },
      { id: 'hobbies_interests', label: t('DataPrivacyWizard.dataCategories.hobbies'), isSpecial: false },
      { id: 'relationships', label: t('DataPrivacyWizard.dataCategories.relationships'), isSpecial: false },
      { id: 'photo_visual', label: t('DataPrivacyWizard.dataCategories.photoVisual'), isSpecial: false },
      { id: 'audio', label: t('DataPrivacyWizard.dataCategories.audio'), isSpecial: false },

      // Special categories
      { id: 'political_affiliations', label: t('DataPrivacyWizard.dataCategories.politicalAffiliations'), isSpecial: true },
      { id: 'religious_beliefs', label: t('DataPrivacyWizard.dataCategories.religiousBeliefs'), isSpecial: true },
      { id: 'sexual_orientation', label: t('DataPrivacyWizard.dataCategories.sexualOrientation'), isSpecial: true },
      { id: 'health_data', label: t('DataPrivacyWizard.dataCategories.health'), isSpecial: true },
      { id: 'biometric_data', label: t('DataPrivacyWizard.dataCategories.biometric'), isSpecial: true },
      { id: 'legal_criminal_records', label: t('DataPrivacyWizard.dataCategories.legalRecords'), isSpecial: true }
    ];
    return categories.sort((a, b) => a.isSpecial === b.isSpecial ? 0 : a.isSpecial ? 1 : -1);
  });

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    dataType: getUserLanguage() === 'de' ? 'Benutzerdefiniert' : 'Custom',
    dataTypeNote: '',
    purpose: '',
    dataSubjectCategories: '',
    dataCategories: [],
    retention: getUserLanguage() === 'de' ? '1 Jahr' : '1 Years',
    privacyClassification: 'Medium',
    riskRating: 'Medium',
    dpiaRequired: false,
    processingRole: 'Controller',
    activityOwner: null,
    lawfulBasis: {
      consent: false,
      contract: false,
      legalObligation: false,
      vitalInterests: false,
      publicTask: false,
      legitimateInterests: false
    },
    specialCategoryBasis: {
      explicitConsent: false,
      employmentSocialSecurity: false,
      vitalInterests: false,
      nonProfitOrganization: false,
      publiclyMadeAvailable: false,
      legalClaims: false,
      publicInterest: false,
      healthCareSocial: false,
      publicHealth: false,
      archiveResearchStatistics: false
    },
    recipientCategories: '',
    associatedAssets: [],
    internalRecipients: [],
    externalRecipients: []
  });

  // Initialize form data with existing data if provided
  useEffect(() => {
    if (existingData) {
      setFormData({
        ...existingData,
        associatedAssets: Array.isArray(existingData.associatedAssets) ? existingData.associatedAssets : [],
        internalRecipients: Array.isArray(existingData.internalRecipients) ? existingData.internalRecipients : [],
        externalRecipients: Array.isArray(existingData.externalRecipients) ? existingData.externalRecipients : [],
        activityOwner: existingData.activityOwner || null,
        riskControls: existingData.riskControls || []
      });
    }
  }, [existingData]);


  // Dynamic data type filtering
  const filteredDataTypes = dataTypes.filter(type => {
    return type.preset_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      type.data_type_note.toLowerCase().includes(searchTerm.toLowerCase())
  });

  // Add this method inside the ProcessingActivityForm component
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (name.startsWith('lawfulBasis.')) {
        const basisKey = name.split('.')[1];
        setFormData(prev => ({
          ...prev,
          lawfulBasis: {
            ...prev.lawfulBasis,
            [basisKey]: checked
          }
        }));
      } else {
        setFormData(prev => ({ ...prev, [name]: checked }));
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  // Handle activity owner change
  const handleActivityOwnerChange = (person) => {
    setFormData(prev => ({
      ...prev,
      activityOwner: person
    }));
  };

  // Add this method to open the drawer
  const toggleDrawer = (title, content) => {
    // If drawer is already open with this title, close it
    if (drawerOpen && drawerContent.title === title) {
      setDrawerOpen(false);
    } else {
      // Set drawer content
      setDrawerContent({
        title,
        content: `<div class="prose">${content}</div>`
      });
      // Open drawer
      setDrawerOpen(true);
    }
  };

  // Update the hasErrors method to use a function
  const hasErrors = () => {
    const hasSpecialCategories = formData.dataCategories.some(
      cat => dataCategories.find(c => c.id === cat && c.isSpecial)
    );

    return !formData.name ||
      !formData.purpose ||
      !Object.values(formData.lawfulBasis).some(Boolean) ||
      (hasSpecialCategories && !Object.values(formData.specialCategoryBasis || {}).some(Boolean));
  };

  const userLang = getUserLanguage();

  // Handle data type change
  const handleDataTypeChange = (type) => {
    const userLang = getUserLanguage();

    setFormData(prev => {
      const updatedCategories = [];

      // Check which data categories are collected based on the preset
      dataCategories.forEach(category => {
        const collectionKey = `collects_${category.id}`;
        if (type[collectionKey]) {
          updatedCategories.push(category.id);
        }
      });

      return {
        ...prev,
        dataType: userLang === 'de' ? type.preset_name_de : type.preset_name,
        dataTypeNote: userLang === 'de' ? type.data_type_note_de : type.data_type_note,
        purpose: userLang === 'de' ? type.processing_purpose_de : type.processing_purpose,
        privacyClassification: type.default_privacy_classification || 'Medium',
        riskRating: type.default_risk_rating || 'Medium',
        retention: type.default_retention_period || (userLang === 'de' ? '1 Jahr' : '1 Years'),
        dataCategories: updatedCategories,
        recipientCategories: userLang === 'de' ? type.typical_recipients_de : type.typical_recipients,
        lawfulBasis: {
          consent: !!type.lawful_basis_consent,
          contract: !!type.lawful_basis_contract,
          legalObligation: !!type.lawful_basis_legal_obligation,
          vitalInterests: !!type.lawful_basis_vital_interests,
          publicTask: !!type.lawful_basis_public_task,
          legitimateInterests: !!type.lawful_basis_legitimate_interests
        }
      };
    });
  };

  // Initialize form data with existing data if provided
  useEffect(() => {
    if (existingData) {
      setFormData({
        ...existingData,
        associatedAssets: existingData.associatedAssets || [],
        internalRecipients: existingData.internalRecipients || [],
        externalRecipients: existingData.externalRecipients || [],
        activityOwner: existingData.activityOwner || null
      });
    }
  }, [existingData]);

  // Modify risk assessment based on special categories
  useEffect(() => {
    const hasSpecialCategories = formData.dataCategories.some(
      cat => dataCategories.find(c => c.id === cat && c.isSpecial)
    );

    if (hasSpecialCategories) {
      setFormData(prev => ({
        ...prev,
        privacyClassification: 'High',
        riskRating: 'High',
        dpiaRequired: true
      }));
    }
  }, [formData.dataCategories]);

  // Handle category changes
  const handleCategoryChange = (categoryId) => {
    setFormData(prev => {
      const updatedCategories = [...prev.dataCategories];

      if (updatedCategories.includes(categoryId)) {
        // Remove category if already selected
        const index = updatedCategories.indexOf(categoryId);
        updatedCategories.splice(index, 1);
      } else {
        // Add category if not selected
        updatedCategories.push(categoryId);
      }

      // Check if any special category data is now selected, and update flags
      const hasSpecialCategories = updatedCategories.some(cat =>
        dataCategories.find(c => c.id === cat && c.isSpecial)
      );

      return {
        ...prev,
        dataCategories: updatedCategories,
        privacyClassification: hasSpecialCategories ? 'High' : prev.privacyClassification,
        riskRating: hasSpecialCategories ? 'High' : prev.riskRating,
        dpiaRequired: hasSpecialCategories || prev.dpiaRequired
      };
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the activityOwner for API submission
    const formattedFormData = {
      ...formData,
      // Ensure associatedAssets is an array
      associatedAssets: Array.isArray(formData.associatedAssets) ? formData.associatedAssets : [],
      // If we have a person asset ID, use it, otherwise just store the name
      activityOwner: formData.activityOwner ?
        (formData.activityOwner.id ?
          { id: formData.activityOwner.id, name: formData.activityOwner.name } :
          { name: formData.activityOwner.name, isCustom: true }
        ) : null
    };

    onSave(formattedFormData);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit}>
        {/* Basic Information */}
        <div className="mb-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {t('DataPrivacyWizard.basicInfo')}
          </h3>

          {/* Data Type Selection with Search */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('DataPrivacyWizard.dataType')}
              <button
                type="button"
                onClick={() => toggleDrawer(
                  t('DataPrivacyWizard.dataTypeInfo.title'),
                  t('DataPrivacyWizard.dataTypeInfo.content')
                )}
                className="ml-1 text-gray-400 hover:text-gray-500"
              >
                <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
              </button>
            </label>

            {/* Data Type List */}
            <div className="max-h-64 overflow-y-auto border rounded-md bg-white">
              {/* Custom/Other option always first */}
              <div
                key="custom"
                className={`flex justify-between items-center p-3 hover:bg-gray-50 cursor-pointer ${formData.dataType === (getUserLanguage() === 'de' ? 'Benutzerdefiniert' : 'Custom')
                  ? 'bg-secondary-color text-white'
                  : ''
                  }`}
                onClick={() => {
                  handleDataTypeChange({
                    preset_name: 'Custom',
                    preset_name_de: 'Benutzerdefiniert',
                    data_type_note: '',
                    data_type_note_de: '',
                    processing_purpose: '',
                    processing_purpose_de: '',
                    typical_recipients: '',
                    typical_recipients_de: '',
                    default_retention_period: getUserLanguage() === 'de' ? '1 Jahr' : '1 Years'
                  });
                }}
              >
                <div>
                  <div className="font-medium">
                    {getUserLanguage() === 'de' ? 'Benutzerdefiniert' : 'Custom'}
                  </div>
                  <div className={`text-sm ${formData.dataType === (getUserLanguage() === 'de' ? 'Benutzerdefiniert' : 'Custom')
                    ? 'text-gray-100'
                    : 'text-gray-500'
                    }`}>
                    {t('DataPrivacyWizard.customDataTypeDescription')}
                  </div>
                </div>
                {formData.dataType === (getUserLanguage() === 'de' ? 'Benutzerdefiniert' : 'Custom') && (
                  <CheckIcon className="h-5 w-5 text-white" />
                )}
              </div>

              {/* Preset data types */}
              {filteredDataTypes.map(type => (
                <div
                  key={type.id}
                  className={`flex justify-between items-center p-3 hover:bg-gray-50 cursor-pointer ${formData.dataType === (getUserLanguage() === 'de' ? type.preset_name_de : type.preset_name)
                    ? 'bg-secondary-color text-white'
                    : ''
                    }`}
                  onClick={() => {
                    handleDataTypeChange({
                      ...type,
                      preset_name: type.preset_name,
                      preset_name_de: type.preset_name_de,
                      data_type_note: type.data_type_note,
                      data_type_note_de: type.data_type_note_de,
                      processing_purpose: type.processing_purpose,
                      processing_purpose_de: type.processing_purpose_de,
                      typical_recipients: type.typical_recipients,
                      typical_recipients_de: type.typical_recipients_de
                    });
                  }}
                >
                  <div>
                    <div className="font-medium">
                      {getUserLanguage() === 'de' ? type.preset_name_de : type.preset_name}
                    </div>
                    <div className={`text-sm ${formData.dataType === (getUserLanguage() === 'de' ? type.preset_name_de : type.preset_name) ? 'text-gray-100' : 'text-gray-500'}`}>
                      {getUserLanguage() === 'de'
                        ? type.data_type_note_de
                        : type.data_type_note
                      }
                    </div>
                  </div>
                  {formData.dataType === (getUserLanguage() === 'de' ? type.preset_name_de : type.preset_name) && (
                    <CheckIcon className="h-5 w-5 text-white" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('DataPrivacyWizard.activityName')}
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                required
              />
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('DataPrivacyWizard.description')}
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('DataPrivacyWizard.purpose')}
              <span className="text-red-500 ml-1">*</span>
              <button
                type="button"
                onClick={() => toggleDrawer(
                  t('DataPrivacyWizard.purposeInfo.title'),
                  t('DataPrivacyWizard.purposeInfo.content')
                )}
                className="ml-1 text-gray-400 hover:text-gray-500"
              >
                <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
              </button>
            </label>
            <textarea
              name="purpose"
              value={formData.purpose}
              onChange={handleInputChange}
              rows={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
              required
            />
          </div>

          {/* Activity Owner - Using PersonSelector component */}
          <div className="mt-4">
            <PersonSelector
              label={t('DataPrivacyWizard.activityOwner')}
              value={formData.activityOwner}
              onChange={handleActivityOwnerChange}
              placeholder={t('DataPrivacyWizard.selectOrEnterOwner')}
              userLang={getUserLanguage()}
              filter={{
                categoryId: ASSET_FILTER_CONSTANTS.HUMAN_CATEGORY_ID
              }}
            />
          </div>

          {/* Associated Assets Section */}
          <div className="mb-6 mt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              {t('DataPrivacyWizard.associatedAssets')}
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              {t('DataPrivacyWizard.associatedAssetsDescription')}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Array.isArray(formData.associatedAssets) && formData.associatedAssets.map((asset, index) => {
                // Skip rendering if asset is null, undefined, or not an object
                if (!asset || typeof asset !== 'object') return null;

                return (
                  <AssetCard
                    key={index}
                    asset={asset}
                    userLang={getUserLanguage()}
                    onRemove={() => {
                      const updatedAssets = [...(formData.associatedAssets || [])];
                      updatedAssets.splice(index, 1);
                      setFormData(prev => ({ ...prev, associatedAssets: updatedAssets }));
                    }}
                  />
                );
              })}

              {/* Add Asset Card */}
              <button
                type="button"
                onClick={() => setAssociatedAssetModalOpen(true)}
                className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center hover:border-primary-color hover:bg-gray-50 transition-colors"
              >
                <div className="flex justify-center items-center">
                  <PlusIcon className="h-6 w-6 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-500">
                    {t('DataPrivacyWizard.addAssociatedAsset')}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>

        {/* Data Subject Categories */}
        <div className="mb-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {t('DataPrivacyWizard.dataSubjects')}
          </h3>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('DataPrivacyWizard.dataSubjectCategories')}
              <button
                type="button"
                onClick={() => toggleDrawer(
                  t('DataPrivacyWizard.dataSubjectsInfo.title'),
                  t('DataPrivacyWizard.dataSubjectsInfo.content')
                )}
                className="ml-1 text-gray-400 hover:text-gray-500"
              >
                <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
              </button>
            </label>
            <textarea
              name="dataSubjectCategories"
              value={formData.dataSubjectCategories}
              onChange={handleInputChange}
              placeholder={t('DataPrivacyWizard.dataSubjectCategoriesPlaceholder')}
              rows={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
            />
          </div>
        </div>

        {/* Data Categories */}
        <div className="mb-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4 flex items-center">
            {t('DataPrivacyWizard.dataCategoriesTitle')}
            <button
              type="button"
              onClick={() => toggleDrawer(
                t('DataPrivacyWizard.dataCategoriesInfo.title'),
                t('DataPrivacyWizard.dataCategoriesInfo.content')
              )}
              className="ml-1 text-gray-400 hover:text-gray-500"
            >
              <QuestionMarkCircleIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Non-special categories */}
            {dataCategories
              .filter(category => !category.isSpecial)
              .map(category => (
                <div key={category.id} className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id={`category-${category.id}`}
                      type="checkbox"
                      checked={formData.dataCategories.includes(category.id)}
                      onChange={() => handleCategoryChange(category.id)}
                      className="h-4 w-4 text-primary-color border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={`category-${category.id}`}
                      className="font-medium text-gray-700"
                    >
                      {category.label}
                    </label>
                  </div>
                </div>
              ))
            }
            {/* Special categories at the bottom with highlighting */}
            {dataCategories
              .filter(category => category.isSpecial)
              .map(category => (
                <div key={category.id} className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id={`category-${category.id}`}
                      type="checkbox"
                      checked={formData.dataCategories.includes(category.id)}
                      onChange={() => handleCategoryChange(category.id)}
                      className="h-4 w-4 text-primary-color border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm flex flex-col">
                    <label
                      htmlFor={`category-${category.id}`}
                      className="font-medium text-gray-700"
                    >
                      {category.label}
                    </label>
                    <span className="text-xs font-normal text-yellow-700">
                      {t('DataPrivacyWizard.specialCategory')}
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        {/* Recipients Section */}
        <div className="mb-6">
          <div className="space-y-8">
            {/* Internal Recipients */}
            <div>
              <h4 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                {t('DataPrivacyWizard.internalRecipients')}
              </h4>
              <p className="text-sm text-gray-600 mb-4">
                {t('DataPrivacyWizard.internalRecipientsDescription')}
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {internalRecipientsModalOpen && (
                  <AssetSelectionModal
                    isOpen={internalRecipientsModalOpen}
                    onClose={() => setInternalRecipientsModalOpen(false)}
                    existingAssets={Array.isArray(formData.internalRecipients) ? formData.internalRecipients : []}
                    onSelect={(selectedRecipients) => {
                      // Ensure selectedRecipients is always an array
                      const recipientsArray = Array.isArray(selectedRecipients)
                        ? selectedRecipients
                        : [selectedRecipients].filter(Boolean);

                      // Update the form data by comparing with existing recipients
                      setFormData(prev => {
                        // Get existing recipients (ensure it's an array)
                        const existingRecipients = Array.isArray(prev.internalRecipients)
                          ? prev.internalRecipients
                          : [];

                        // Get IDs of existing and selected recipients for comparison
                        const existingIds = existingRecipients.map(r => r.id);
                        const selectedIds = recipientsArray.map(r => r.id);

                        // If exactly one asset is selected and we had multiple before,
                        // it's likely the user deselected assets in the modal
                        if (existingRecipients.length > 1 && recipientsArray.length === 1) {
                          return {
                            ...prev,
                            internalRecipients: recipientsArray
                          };
                        }

                        // If the count is the same or higher, it's an addition or replacement
                        return {
                          ...prev,
                          internalRecipients: recipientsArray
                        };
                      });

                      setInternalRecipientsModalOpen(false);
                    }}
                    // Filter to only show organizational structure assets
                    filter={{
                      classId: ASSET_FILTER_CONSTANTS.ORGANIZATIONAL_STRUCTURE_CLASS_ID
                    }}
                    title={t('DataPrivacyWizard.selectInternalRecipients')}
                  />
                )}

                {/* Display selected internal recipients */}
                {Array.isArray(formData.internalRecipients) && formData.internalRecipients.map((recipient, index) => (
                  <AssetCard
                    key={index}
                    asset={recipient}
                    userLang={getUserLanguage()}
                    onRemove={() => {
                      const updatedRecipients = [...formData.internalRecipients];
                      updatedRecipients.splice(index, 1);
                      setFormData(prev => ({ ...prev, internalRecipients: updatedRecipients }));
                    }}
                  />
                ))}

                {/* Add Internal Recipient Card */}
                <button
                  type="button"
                  onClick={() => setInternalRecipientsModalOpen(true)}
                  className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center hover:border-primary-color hover:bg-gray-50 transition-colors"
                >
                  <div className="flex justify-center items-center">
                    <PlusIcon className="h-6 w-6 text-gray-400 mr-2" />
                    <span className="text-sm text-gray-500">
                      {t('DataPrivacyWizard.addInternalRecipient')}
                    </span>
                  </div>
                </button>
              </div>
            </div>

            {/* External Recipients */}
            <div>
              <h4 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                {t('DataPrivacyWizard.externalRecipients')}
              </h4>
              <p>
                <span className="text-sm font-medium text-gray-600 mb-4">
                  {t('DataPrivacyWizard.externalRecipientsDescription')}
                </span>
                <span className="text-sm text-gray-600 font-semibold mb-4">
                  {t('DataPrivacyWizard.externalRecipientsDescription2')}
                </span>
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Existing external recipients */}
                {externalRecipientsModalOpen && (
                  <AssetSelectionModal
                    isOpen={externalRecipientsModalOpen}
                    onClose={() => setExternalRecipientsModalOpen(false)}
                    existingAssets={Array.isArray(formData.externalRecipients) ? formData.externalRecipients : []}
                    onSelect={(selectedRecipients) => {
                      // Ensure selectedRecipients is always an array
                      const recipientsArray = Array.isArray(selectedRecipients)
                        ? selectedRecipients
                        : [selectedRecipients].filter(Boolean);

                      // Update the form data by comparing with existing recipients
                      setFormData(prev => {
                        // Get existing recipients (ensure it's an array)
                        const existingRecipients = Array.isArray(prev.externalRecipients)
                          ? prev.externalRecipients
                          : [];

                        // Get IDs of existing and selected recipients for comparison
                        const existingIds = existingRecipients.map(r => r.id);
                        const selectedIds = recipientsArray.map(r => r.id);

                        // If exactly one asset is selected and we had multiple before,
                        // it's likely the user deselected assets in the modal
                        if (existingRecipients.length > 1 && recipientsArray.length === 1) {
                          return {
                            ...prev,
                            externalRecipients: recipientsArray
                          };
                        }

                        // If the count is the same or higher, it's an addition or replacement
                        return {
                          ...prev,
                          externalRecipients: recipientsArray
                        };
                      });

                      setExternalRecipientsModalOpen(false);
                    }}
                    // Filter to only show suppliers/vendors and partnerships assets
                    filter={{
                      classIds: [
                        ASSET_FILTER_CONSTANTS.SUPPLIERS_VENDORS_CLASS_ID,
                        ASSET_FILTER_CONSTANTS.PARTNERSHIPS_CLASS_ID
                      ]
                    }}
                    title={t('DataPrivacyWizard.selectExternalRecipients')}
                  />
                )}

                {/* Display selected external recipients */}
                {Array.isArray(formData.externalRecipients) && formData.externalRecipients.map((recipient, index) => (
                  <AssetCard
                    key={index}
                    asset={recipient}
                    userLang={getUserLanguage()}
                    onRemove={() => {
                      const updatedRecipients = [...formData.externalRecipients];
                      updatedRecipients.splice(index, 1);
                      setFormData(prev => ({ ...prev, externalRecipients: updatedRecipients }));
                    }}
                  />
                ))}

                {/* Add External Recipient Card */}
                <button
                  type="button"
                  onClick={() => setExternalRecipientsModalOpen(true)}
                  className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center hover:border-primary-color hover:bg-gray-50 transition-colors"
                >
                  <div className="flex justify-center items-center">
                    <PlusIcon className="h-6 w-6 text-gray-400 mr-2" />
                    <span className="text-sm text-gray-500">
                      {t('DataPrivacyWizard.addExternalRecipient')}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Risk Assessment */}
        <div className="mb-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {t('DataPrivacyWizard.riskAssessment')}
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('DataPrivacyWizard.privacyClassification')}
                <button
                  type="button"
                  onClick={() => toggleDrawer(
                    t('DataPrivacyWizard.privacyClassificationInfo.title'),
                    t('DataPrivacyWizard.privacyClassificationInfo.content')
                  )}
                  className="ml-1 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
                </button>
              </label>
              <select
                name="privacyClassification"
                value={formData.privacyClassification}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
              >
                <option value="Low">{t('DataPrivacyWizard.low')}</option>
                <option value="Medium">{t('DataPrivacyWizard.medium')}</option>
                <option value="High">{t('DataPrivacyWizard.high')}</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('DataPrivacyWizard.riskRating')}
                <button
                  type="button"
                  onClick={() => toggleDrawer(
                    t('DataPrivacyWizard.riskRatingInfo.title'),
                    t('DataPrivacyWizard.riskRatingInfo.content')
                  )}
                  className="ml-1 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
                </button>
              </label>
              <select
                name="riskRating"
                value={formData.riskRating}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
              >
                <option value="Low">{t('DataPrivacyWizard.low')}</option>
                <option value="Medium">{t('DataPrivacyWizard.medium')}</option>
                <option value="High">{t('DataPrivacyWizard.high')}</option>
              </select>
            </div>
          </div>

          <div className="mt-4 mb-6">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="dpiaRequired"
                  name="dpiaRequired"
                  type="checkbox"
                  checked={formData.dpiaRequired}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-primary-color border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="dpiaRequired" className="font-medium text-gray-700">
                  {t('DataPrivacyWizard.dpiaRequired')}
                </label>
                <p className="text-gray-500 text-xs">{t('DataPrivacyWizard.dpiaRequiredDescriptionInfo')}</p>
              </div>
            </div>

            {formData.dataCategories.some(cat =>
              dataCategories.find(c => c.id === cat && c.isSpecial)
            ) && (
                <div className="mt-2 pl-5 p-3 bg-yellow-50">
                  <p className="text-sm text-yellow-700">
                    {t('DataPrivacyWizard.specialCategoriesDPIAExplanation')}
                  </p>
                </div>
              )}
          </div>
        </div>

        {/* Legal Basis */}
        <div className="mb-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {t('DataPrivacyWizard.lawfulBasis')}
            <button
              type="button"
              onClick={() => toggleDrawer(
                t('DataPrivacyWizard.lawfulBasisInfo.title'),
                t('DataPrivacyWizard.lawfulBasisInfo.content')
              )}
              className="ml-1 text-gray-400 hover:text-gray-500"
            >
              <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
            </button>
          </h3>

          <div className="space-y-4">
            {Object.entries(formData.lawfulBasis).map(([key, value]) => (
              <div key={key} className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id={`lawfulBasis.${key}`}
                    name={`lawfulBasis.${key}`}
                    type="checkbox"
                    checked={value}
                    onChange={handleInputChange}
                    className="h-4 w-4 text-primary-color border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3">
                  <label
                    htmlFor={`lawfulBasis.${key}`}
                    className="font-medium text-sm text-gray-700"
                  >
                    {t(`DataPrivacyWizard.${key}`)}
                  </label>
                  <p className="text-gray-500 text-xs">
                    {t(`DataPrivacyWizard.${key}Description`)}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {!Object.values(formData.lawfulBasis).some(Boolean) && (
            <div className="mt-4 p-3 bg-red-50 rounded-md">
              <p className="text-sm text-red-700">
                {t('DataPrivacyWizard.lawfulBasisRequired')}
              </p>
            </div>
          )}
        </div>

        {/* Special Category Processing Basis - shown only when special categories are selected */}
        {formData.dataCategories.some(cat =>
          dataCategories.find(c => c.id === cat && c.isSpecial)
        ) && (
            <div className="mb-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                {t('DataPrivacyWizard.specialCategoryProcessingBasis')}
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                {t('DataPrivacyWizard.specialCategoryProcessingBasisDescription')}
              </p>

              <div className="space-y-4">
                {[
                  {
                    key: 'explicitConsent',
                    label: t('DataPrivacyWizard.explicitConsent'),
                    description: t('DataPrivacyWizard.explicitConsentDescription')
                  },
                  {
                    key: 'employmentSocialSecurity',
                    label: t('DataPrivacyWizard.employmentSocialSecurity'),
                    description: t('DataPrivacyWizard.employmentSocialSecurityDescription')
                  },
                  {
                    key: 'vitalInterests',
                    label: t('DataPrivacyWizard.vitalInterests'),
                    description: t('DataPrivacyWizard.vitalInterestsDescription')
                  },
                  {
                    key: 'nonProfitOrganization',
                    label: t('DataPrivacyWizard.nonProfitOrganization'),
                    description: t('DataPrivacyWizard.nonProfitOrganizationDescription')
                  },
                  {
                    key: 'publiclyMadeAvailable',
                    label: t('DataPrivacyWizard.publiclyMadeAvailable'),
                    description: t('DataPrivacyWizard.publiclyMadeAvailableDescription')
                  },
                  {
                    key: 'legalClaims',
                    label: t('DataPrivacyWizard.legalClaims'),
                    description: t('DataPrivacyWizard.legalClaimsDescription')
                  },
                  {
                    key: 'publicInterest',
                    label: t('DataPrivacyWizard.publicInterest'),
                    description: t('DataPrivacyWizard.publicInterestDescription')
                  },
                  {
                    key: 'healthCareSocial',
                    label: t('DataPrivacyWizard.healthCareSocial'),
                    description: t('DataPrivacyWizard.healthCareSocialDescription')
                  },
                  {
                    key: 'publicHealth',
                    label: t('DataPrivacyWizard.publicHealth'),
                    description: t('DataPrivacyWizard.publicHealthDescription')
                  },
                  {
                    key: 'archiveResearchStatistics',
                    label: t('DataPrivacyWizard.archiveResearchStatistics'),
                    description: t('DataPrivacyWizard.archiveResearchStatisticsDescription')
                  }
                ].map(({ key, label, description }) => (
                  <div key={key} className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id={`specialCategoryBasis.${key}`}
                        name={`specialCategoryBasis.${key}`}
                        type="checkbox"
                        checked={formData.specialCategoryBasis?.[key] || false}
                        onChange={(e) => {
                          setFormData(prev => ({
                            ...prev,
                            specialCategoryBasis: {
                              ...prev.specialCategoryBasis,
                              [key]: e.target.checked
                            }
                          }));
                        }}
                        className="h-4 w-4 text-primary-color border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor={`specialCategoryBasis.${key}`}
                        className="font-medium text-sm text-gray-700"
                      >
                        {label}
                      </label>
                      <p className="text-gray-500 text-xs">
                        {description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Warning if no basis is selected */}
              {!Object.values(formData.specialCategoryBasis || {}).some(Boolean) && (
                <div className="mt-4 p-3 bg-red-50 rounded-md">
                  <p className="text-sm text-red-700">
                    {t('DataPrivacyWizard.specialCategoryBasisRequired')}
                  </p>
                </div>
              )}
            </div>
          )}

        {/* Form Actions */}
        <div className="space-y-6">
          {/* Remove the form tag, keep everything else the same */}
          {/* Form Actions at the bottom now need to use type="button" */}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onCancel}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
            >
              {t('DataPrivacyWizard.cancel')}
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={hasErrors()}
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${hasErrors()
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                }`}
            >
              {t('DataPrivacyWizard.confirmDataProcessing')}
            </button>
          </div>
        </div>
      </form>

      {/* Modals for Associated Assets and Recipients */}
      {associatedAssetModalOpen && (
        <AssetSelectionModal
          isOpen={associatedAssetModalOpen}
          onClose={() => setAssociatedAssetModalOpen(false)}
          existingAssets={Array.isArray(formData.associatedAssets) ? formData.associatedAssets : []}
          onSelect={(newAssets) => {
            // Ensure newAssets is always an array
            const assetsArray = Array.isArray(newAssets) ? newAssets : [newAssets].filter(Boolean);

            // Merge the new assets with existing ones
            setFormData(prev => {
              // Ensure previous associatedAssets is an array
              const currentAssets = Array.isArray(prev.associatedAssets) ? prev.associatedAssets : [];

              // For now, let's assume if assetsArray.length === 1, it's a new asset to add
              // otherwise, it's a complete replacement
              const updatedAssets =
                (assetsArray.length === 1 && prev.associatedAssets && prev.associatedAssets.length > 0)
                  ? [...currentAssets, ...assetsArray]
                  : assetsArray;

              return {
                ...prev,
                associatedAssets: updatedAssets
              };
            });

            setAssociatedAssetModalOpen(false);
          }}
          // No filter passed, so all asset categories and classes are available
          title={t('DataPrivacyWizard.selectAssociateAsset')}
        />
      )}

      <WizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
}

export default ProcessingActivityForm;