// src/pages/wizards/DataPrivacyWizard//DataPrivacyWizardRopa.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { PlusIcon, TrashIcon, PencilSquareIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import ProcessingActivityForm from '../../../components/wizard_components/ProcessingActivityForm';
import axios from 'axios';

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const DataPrivacyWizardRopa = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'ropa');
  const [dataTypes, setDataTypes] = useState([]);
  const [expandedActivity, setExpandedActivity] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [riskControls, setRiskControls] = useState([]);

  // Fetch data types from backend
  useEffect(() => {
    const fetchDataTypes = async () => {
      try {
        const response = await axios.get('/api/v1/static_data/data_types');
        setDataTypes(response.data);
      } catch (error) {
        console.error('Error fetching data types:', error);
      }
    };

    fetchDataTypes();
  }, []);

  const initialValues = data || {
    processingActivities: []
  };

  useEffect(() => {
    const fetchStaticData = async () => {
      try {
        const [dataTypesResponse, riskControlsResponse] = await Promise.all([
          axios.get('/api/v1/static_data/data_types'),
          axios.get('/api/v1/static_data/risk_controls')
        ]);

        setDataTypes(dataTypesResponse.data);
        setRiskControls(riskControlsResponse.data);
      } catch (error) {
        console.error('Error fetching static data:', error);
      }
    };

    fetchStaticData();
  }, []);

  useEffect(() => {
    setCurrentSection('ropa');
  }, [setCurrentSection]);

  const validationSchema = Yup.object({
    processingActivities: Yup.array().of(
      Yup.object({
        name: Yup.string().required(t('DataPrivacyWizard.validation.activityNameRequired')),
        dataType: Yup.string().required(t('DataPrivacyWizard.validation.dataTypeRequired')),
        purpose: Yup.string().required(t('DataPrivacyWizard.validation.purposeRequired'))
      })
    )
  });

  // Initialize Formik using useFormik hook
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await saveData(wizardName, 'ropa', values);
        if (result.success) {
          setNotification({
            show: true,
            type: 'success',
            message: t('DataPrivacyWizard.dataSaved'),
          });
          updateWizardData('ropa', values, false);
          updateWizardData('ropa', values, true);
          setTimeout(() => {
            navigate('/data-privacy-wizard/policies');
          }, 1000);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error('Error saving data:', error);
        setNotification({
          show: true,
          type: 'error',
          message: t('DataPrivacyWizard.errorSaving'),
          errors: [error.message],
        });
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode) {
      const result = await saveAsDraft(wizardName, 'ropa', formik.values);
      if (result.success) {
        updateWizardData('ropa', formik.values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, formik.values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = React.useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and formik.values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, formik.values]);

  const handleDraftSubmit = async () => {
    try {
      const result = await saveAsDraft(wizardName, 'ropa', formik.values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('DataPrivacyWizard.draftSaved'),
        });
        updateWizardData('ropa', formik.values, true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('DataPrivacyWizard.errorSavingDraft'),
        errors: [error.message],
      });
    }
  };

  const handleAddActivity = () => {
    setIsCreatingNew(true);
    setExpandedActivity('new');
  };

  const handleDeleteActivity = (index) => {
    const updatedActivities = [...formik.values.processingActivities];
    updatedActivities.splice(index, 1);
    formik.setFieldValue('processingActivities', updatedActivities);

    if (expandedActivity === index) {
      setExpandedActivity(null);
    }
  };

  const handleToggleExpand = (index) => {
    if (expandedActivity === index) {
      setExpandedActivity(null);
    } else {
      setExpandedActivity(index);
      setIsCreatingNew(false);
    }
  };

  const handleSaveActivity = async (activityData, index) => {
    try {
      // Ensure processingActivities is an array
      const currentActivities = formik.values.processingActivities || [];
      const updatedActivities = [...currentActivities];

      if (index === 'new') {
        // Add new activity
        updatedActivities.push(activityData);
      } else {
        // Update existing activity
        updatedActivities[index] = activityData;
      }

      // Save draft with updated activities
      const result = await saveAsDraft(wizardName, 'ropa', {
        processingActivities: updatedActivities
      });

      if (result.success) {
        // Update Formik values
        formik.setFieldValue('processingActivities', updatedActivities);

        // Show notification that activity was added
        setNotification({
          show: true,
          type: 'success',
          message: t('DataPrivacyWizard.processingActivityAdded'),
        });

        // Update wizard data
        updateWizardData('ropa', {
          processingActivities: updatedActivities
        }, true);

        // Close the expanded activity
        setExpandedActivity(null);
        setIsCreatingNew(false);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving processing activity:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('DataPrivacyWizard.errorSavingActivity'),
        errors: [error.message],
      });
    }
  };

  const handleCancelEdit = () => {
    setExpandedActivity(null);
    setIsCreatingNew(false);
  };

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>Error: {error.message}</div>;

  const getDpiaCount = () => {
    return formik.values.processingActivities && formik.values.processingActivities.length
      ? formik.values.processingActivities.filter(activity => activity.dpiaRequired).length
      : 0;
  };

  const getHighRiskCount = () => {
    return formik.values.processingActivities && formik.values.processingActivities.length
      ? formik.values.processingActivities.filter(activity => activity.riskRating === 'High').length
      : 0;
  };

  return (
    <div className="container mx-auto p-4">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <div className="mt-10 md:0">
        <DataPrivacyWizardProgress />
      </div>
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <DataStatusBanner status={dataStatus} />

      <h1 className="text-3xl font-bold mb-6">{t('DataPrivacyWizard.ropa')}</h1>

      <div className="mb-6">
        <p className="text-gray-700">{t('DataPrivacyWizard.ropaDescription')}</p>
      </div>

      {getHighRiskCount() > 0 && (
        <div className="p-4 bg-yellow-50 rounded-md mb-6 flex items-start">
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600 mr-3 flex-shrink-0 mt-0.5" />
          <div>
            <h3 className="text-sm font-medium text-yellow-800">
              {t('DataPrivacyWizard.highRiskActivitiesWarning')}
            </h3>
            <p className="mt-2 text-sm text-yellow-700">
              {t('DataPrivacyWizard.highRiskActivitiesWarningDescription', { count: getHighRiskCount() })}
            </p>
          </div>
        </div>
      )}

      {getDpiaCount() > 0 && (
        <div className="p-4 bg-blue-50 rounded-md mb-6 flex items-start">
          <ExclamationTriangleIcon className="h-6 w-6 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
          <div>
            <h3 className="text-sm font-medium text-blue-800">
              {t('DataPrivacyWizard.dpiaRequiredNotice')}
            </h3>
            <p className="mt-2 text-sm text-blue-700">
              {t('DataPrivacyWizard.dpiaRequiredDescription', { count: getDpiaCount() })}
            </p>
          </div>
        </div>
      )}

      <FormikProvider value={formik}>
        <Form>
          <div className="flex justify-end mb-4">
            <button
              type="button"
              onClick={handleAddActivity}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-secondary-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              {t('DataPrivacyWizard.addActivity')}
            </button>
          </div>

          {isCreatingNew && expandedActivity === 'new' && (
            <div className="mb-6 bg-white border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-gray-50 px-4 py-3 border-b border-gray-300">
                <h3 className="text-lg font-medium text-gray-900">{t('DataPrivacyWizard.newProcessingActivity')}</h3>
              </div>
              <div className="p-8">
                <ProcessingActivityForm
                  onSave={(data) => handleSaveActivity(data, 'new')}
                  onCancel={handleCancelEdit}
                  dataTypes={dataTypes}
                  riskControls={riskControls}
                />
              </div>
            </div>
          )}

          {(!formik.values.processingActivities || formik.values.processingActivities.length === 0) && !isCreatingNew ? (
            <div className="bg-white p-6 text-center rounded-lg border-2 border-dashed border-gray-300">
              <p className="text-gray-500">{t('DataPrivacyWizard.noActivitiesYet')}</p>
              <button
                type="button"
                onClick={handleAddActivity}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-secondary-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                {t('DataPrivacyWizard.addFirstActivity')}
              </button>
            </div>
          ) : (
            <div className="space-y-4 mb-6">
              {formik.values.processingActivities && formik.values.processingActivities.map((activity, index) => (
                <div key={index} className="border border-gray-300 rounded-lg overflow-hidden">
                  <div
                    className="bg-gray-50 px-4 py-3 flex justify-between items-center cursor-pointer"
                    onClick={() => handleToggleExpand(index)}
                  >
                    <div className="flex-grow">
                      <h3 className="text-lg font-medium text-gray-900">{activity.name}</h3>
                      <div className="flex flex-wrap gap-2 mt-1">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          {activity.dataType || t('DataPrivacyWizard.noDataType')}
                        </span>
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${activity.privacyClassification === 'High' ? 'bg-red-100 text-red-800' :
                          activity.privacyClassification === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-green-100 text-green-800'
                          }`}>
                          {t('DataPrivacyWizard.privacyClassificationShort')}: {t(`DataPrivacyWizard.${activity.privacyClassification.toLowerCase()}`)}
                        </span>
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${activity.riskRating === 'High' ? 'bg-red-100 text-red-800' :
                          activity.riskRating === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-green-100 text-green-800'
                          }`}>
                          {t('DataPrivacyWizard.riskRatingShort')}: {t(`DataPrivacyWizard.${activity.riskRating.toLowerCase()}`)}
                        </span>
                        {activity.dpiaRequired && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {t('DataPrivacyWizard.dpiaRequired')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteActivity(index);
                        }}
                        className="text-red-600 hover:text-red-900 mr-4"
                      >
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">{t('DataPrivacyWizard.delete')}</span>
                      </button>
                      {expandedActivity === index ? (
                        <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                      )}
                    </div>
                  </div>

                  {expandedActivity === index && (
                    <div className="p-4 bg-white">
                      <ProcessingActivityForm
                        existingData={activity}
                        onSave={(data) => handleSaveActivity(data, index)}
                        onCancel={handleCancelEdit}
                        dataTypes={dataTypes}
                        riskControls={riskControls}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="mt-8">
            <ActionButtons
              onDraft={handleDraftSubmit}
              onSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              currentPage="ropa"
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default DataPrivacyWizardRopa;